import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import CpfCnpj from "../../../../components/CpfCnpj";

import { useToast } from "../../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../../utils/formValidations";
import api from "../../../../service";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../../components/SelectWithLabel";
import Badge from "../../../../components/Badge";
import { useLoading } from "../../../../hooks/loading";
import axios from "axios";
import Toggle from "../../../../components/Toggle";
import
  TelephoneInputWithLabel,
  {
    getFullTelephoneNumber,
    getCountryFromTelephoneNumber,
  }
from "../../../../components/TelephoneInputWithLabel";

const AdminAssociateCollaboratorsDetail = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { id } = useParams();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState(null);
  const [key, setKey] = useState(null);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const [personType, setPersonType] = useState("F");
  const [selectedUser, setSelectedUser] = useState();
  const [isFastTrack, setIsFastTrack] = useState(false);

  const [documentLabel, setDocumentLabel] = useState(
    intl.formatMessage({ id: "document_cpf" })
  );

  const nationalityOptions = [
    {
      label: intl.formatMessage({ id: "national" }),
      value: false,
    },
    {
      label: intl.formatMessage({ id: "international" }),
      value: true,
    },
  ];

  const history = useHistory();

  const schema = Yup.object().shape({
    user: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    nationality: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    last_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    document: Yup.string(),
    assignment: Yup.string()
      .max(50, intl.formatMessage({ id: "error.max_length" }, { length: 50 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
    credential_name: Yup.string()
      .max(23, intl.formatMessage({ id: "error.max_length" }, { length: 23 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "error.valid_email" }))
      .nullable()
      .when("fast_track", {
        is: (fast_track) => !fast_track,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      }),
    address: Yup.string(),
    telephone: Yup.string()
      .max(30, intl.formatMessage({ id: "error.max_length" }, { length: 30 })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/associate-collaborators/admin/${id}`,
            {
              fastTrack: data.fast_track,
              userId: data.user,
              international: data.nationality,
              name: data.name,
              lastName: data.last_name,
              credentialName: data.credential_name,
              document: data.document,
              jobTitle: data.assignment,
              email: data.email,
              address: data.address,
              telephone: getFullTelephoneNumber(
                data.telephone, data.telephone_selected_country),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.collaborator_update",
              }),
            });
            history.go(-2);
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, id, intl, schema, token, loading]
  );

  useEffect(() => {
    handleLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .all([
        api.get(`/associate-collaborators/admin/${id}`, config),
        api.get(`/users/active-exhibitors-and-service-providers`, config),
      ])
      .then(
        axios.spread((collaboratorResponse, usersResponse) => {
          setData(collaboratorResponse.data.associateCollaboratorOutput);
          switch (
            collaboratorResponse.data.associateCollaboratorOutput.international
          ) {
            case false:
              setPersonType("F");
              setDocumentLabel(
                intl.formatMessage({ id: "document_cpf" })
              );
              break;
            case true:
              setPersonType("I");
              setDocumentLabel(
                intl.formatMessage({ id: "document_passport" })
              );
              break;
            default:
              break;
          }

          const users = usersResponse.data.users.map((user) => ({
            label: user.commercialName,
            value: user.id,
          }));
          setUsers(users);

          handleUserItemSelection(
            users,
            collaboratorResponse.data.associateCollaboratorOutput.userId
          );
          setKey(new Date());
        })
      )
      .catch((error) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + error.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, id, intl, token, handleLoading]);

  const handleUserItemSelection = (users, id) => {
    const item = users.find((user) => user.value === id);
    setSelectedUser(item);
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleTypeChanges = (value) => {
    formRef.current.setFieldValue("document", "");
    switch (value) {
      case false:
        setPersonType("F");
        setDocumentLabel(`${intl.formatMessage({ id: "document" })} (CPF)`);
        break;
      case true:
        setPersonType("I");
        setDocumentLabel(
          `${intl.formatMessage({ id: "document" })} (${intl.formatMessage({
            id: "passport",
          })})`
        );
        break;
      default:
        break;
    }
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            fast_track: data?.fastTrack,
            user: selectedUser,
            nationality: {
              label: nationalityOptions.filter(
                (v) => v.value === data?.international
              )[0]?.label,
              value: data?.international,
            },
            credential_name: data?.credentialName,
            name: data?.name,
            last_name: data?.lastName,
            document: data?.document,
            assignment: data?.jobTitle,
            email: data?.email,
            address: data?.address,
            telephone: data?.telephone,
            telephone_selected_country:
              getCountryFromTelephoneNumber(data?.telephone),
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="editing_collaborator" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.go(-2)}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Toggle
                name="fast_track"
                label={intl.formatMessage({
                  id: "fast_track",
                })}
                onClick={() => setIsFastTrack(!isFastTrack)}
              />
              <SelectWithLabel
                name="user"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "company" })}
                placeholder={intl.formatMessage({ id: "company" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={users}
                required
              />
              <SelectWithLabel
                name="nationality"
                label={intl.formatMessage({ id: "nationality" })}
                placeholder={intl.formatMessage({ id: "nationality" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={nationalityOptions}
                onChange={(e) => handleTypeChanges(e.value)}
                required
              />
              <InputWithHorizontalLabel
                name="name"
                placeholder={intl.formatMessage({
                  id: "name",
                })}
                label={intl.formatMessage({
                  id: "name",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="last_name"
                placeholder={intl.formatMessage({
                  id: "last_name",
                })}
                label={intl.formatMessage({
                  id: "last_name",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="credential_name"
                placeholder={intl.formatMessage({
                  id: "credential_name",
                })}
                label={intl.formatMessage({
                  id: "credential_name",
                })}
                validationBlur={validationBlur}
                maxLength="23"
                labelWidth="150px"
                required
              />
              <CpfCnpj
                name="document"
                placeholder={documentLabel}
                label={documentLabel}
                hasLabel
                labelOrientation="horizontal"
                personType={personType}
                validationBlur={validationBlur}
                labelWidth="150px"
              />
              <InputWithHorizontalLabel
                name="assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={validationBlur}
                maxLength="50"
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="email"
                placeholder="E-mail"
                label="E-mail"
                validationBlur={validationBlur}
                labelWidth="150px"
              />
              <InputWithHorizontalLabel
                name="address"
                placeholder={intl.formatMessage({
                  id: "address",
                })}
                label={intl.formatMessage({
                  id: "address",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
              />
              <TelephoneInputWithLabel
                name="telephone"
                placeholder={intl.formatMessage({
                  id: "telephone",
                })}
                label={intl.formatMessage({
                  id: "telephone",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default AdminAssociateCollaboratorsDetail;
