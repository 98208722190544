import React, { useState, useEffect, useCallback, useMemo } from "react";

import { useIntl } from "react-intl";

import { Input, Tooltip } from "antd";
import Select from "../../newUXComponents/Select";

import { useDispatch, useSelector } from "react-redux";

import useNotification from "../../hooks/newUXNotification";
import { useLoading } from "../../hooks/loading";

import { Prompt } from "react-router";

import {
  getContractByExhibitor,
  getOrderByContract,
  getContractByServiceProvider,
  changeHeadbandExhibitor,
} from "../../redux/modules/contract/actions";

import { convertToDateOnly } from "../../utils/date";
import { getLogoSrcFromHtml } from "../../utils/htmlManipulation";

import provision_types from "../../constants/provisionTypes";

import Info from "../../newUXAssets/icons/Info";
import Calendar from "../../newUXAssets/icons/Calendar";
import Pin from "../../newUXAssets/icons/Pin";
import Image from "../../newUXAssets/icons/Image";
import File from "../../newUXAssets/icons/File";
import UserCircle from "../../newUXAssets/icons/UserCircle";
import PaperPlane from "../../newUXAssets/icons/PaperPlane";
import Star from "../../newUXAssets/icons/Star";
import Check from "../../newUXAssets/icons/Check";
import Ticket from "../../newUXAssets/icons/Ticket";
import HashStraight from "../../newUXAssets/icons/HashStraight";
import Question from "../../newUXAssets/icons/Question";
import UserFocus from "../../newUXAssets/icons/UserFocus";
import AddressBook from "../../newUXAssets/icons/AddressBook";

import ContractProductsServices from "./ContractProductsServices";
import EcommerceProductsServices from "./EcommerceProductsServices";

import {
  Container,
  Title,
  Content,
  Hr,
  Banner,
  BoxContent,
  Box,
  TitleBox,
  Label,
  InfoContract,
  GroupLabel,
  SpecialConditions,
  Booth,
  FormField,
  Btn,
  HTMLCanvas,
  DocumentOpenImage,
} from "./styles";

const Contract = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const notification = useNotification();
  const optionsProvision = provision_types();
  const { handleLoading } = useLoading();

  const [frontSign, setFrontSign] = useState("");
  const [boothSelected, setBoothSelected] = useState({});

  const { contractInfo, order } = useSelector((state) => state.contract);
  const { contract, event } = useSelector((state) => state.initialSettings);
  const { user, permission } = useSelector((state) => state.auth);

  const havePermission = useMemo(() => {
    return permission.includes("EDIT_HEADBAND");
  }, [permission]);

  const boothsOptions = useMemo(() => {
    if (contractInfo?.opportunity?.booths.length
        && contractInfo?.opportunity?.booths[0].boothId) {
      return contractInfo?.opportunity?.booths.map((item) => ({
        label: item.boothNumber,
        value: item.boothId,
      }));
    }

    return [];
  }, [contractInfo]);

  const currentBoothSelected = useMemo(() => {
    if (contractInfo?.opportunity?.booths.length && boothSelected) {
      return contractInfo?.opportunity?.booths.find((item) => {
        return item.boothId === boothSelected;
      });
    }

    return;
  }, [contractInfo, boothSelected]);

  const responsible = useMemo(() => {
    if (
      contractInfo?.opportunity?.billingContact?.firstName &&
      contractInfo?.opportunity?.billingContact?.lastName
    ) {
      return (
        ` ${contractInfo?.opportunity?.billingContact?.firstName} ` +
        contractInfo?.opportunity?.billingContact?.lastName
      );
    }

    return " - ";
  }, [contractInfo]);

  const filterProvisionTypes = useMemo(() => {
    if (!contractInfo?.opportunity?.provisionTypes.length)
      return [
        {
          label: " - ",
        },
      ];

    return (
      contractInfo?.opportunity?.provisionTypes.length &&
      contractInfo?.opportunity?.provisionTypes.map((types) => {
        return optionsProvision.find((item) => item.value === types);
      })
    );
  }, [contractInfo?.opportunity?.provisionTypes, optionsProvision]);

  const callbackError = useCallback(
    (message) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-credentials-associate-error"
      );
    },
    [notification, intl, handleLoading]
  );

  const handleGetInfoContract = useCallback(() => {
    if (user?.role.type === "EXHIBITOR") {
      dispatch(
        getContractByExhibitor(
          {
            eventId: event.value,
            contractId: contract.value,
          },
          callbackError
        )
      );
      dispatch(
        getOrderByContract(
          {
            contractNumber: contract.label,
          },
          callbackError
        )
      );
    } else {
      dispatch(
        getContractByServiceProvider(
          {
            eventId: event.value,
            contractId: contract.value,
          },
          callbackError
        )
      );
    }
  }, [callbackError, contract, dispatch, event, user]);

  const callbackSuccess = useCallback(() => {
    handleLoading(false);
    notification.displaySuccess(
      intl.formatMessage({
        id: "name_on_forehead_saved_successfully",
      }),
      "change-headband-sucess"
    );
    handleGetInfoContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, intl]);

  const handleSaveHeadBand = useCallback(() => {
    handleLoading(true);
    dispatch(
      changeHeadbandExhibitor(
        {
          opportunityId: contractInfo?.opportunity?.opportunityId,
          headband: frontSign,
        },
        callbackSuccess,
        callbackError
      )
    );
  }, [
    dispatch,
    contractInfo?.opportunity?.opportunityId,
    frontSign,
    callbackSuccess,
    callbackError,
    handleLoading,
  ]);

  const handleBlockedNavigation = () => {
    if (frontSign.length < 1 && user?.role.type === "EXHIBITOR") {
      notification.displayInfo(
        intl.formatMessage({
          id: "you_need_to_add_a_forehead_to_this_contract",
        }),
        "add-headband"
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (contractInfo?.opportunity?.headband) {
      setFrontSign(contractInfo?.opportunity?.headband);
    }
  }, [contractInfo?.opportunity?.headband]);

  useEffect(() => {
    if (boothsOptions && boothsOptions.length === 1) {
      setBoothSelected(boothsOptions[0]?.value);
    } else {
      setBoothSelected({});
    }
  }, [boothsOptions]);

  useEffect(() => {
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
    } else {
      handleGetInfoContract();

      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.value, event.value]);

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "contract",
        })}
      </Title>
      <Hr />
      {contract?.value && (
        <Content>
          {contractInfo?.banner && (
            <Banner>
              <img
                src={contractInfo?.banner}
                width="100%"
                alt="banner-do-contrato"
              />
            </Banner>
          )}

          <Prompt
            when={
              frontSign.length < 1 &&
              user?.role.type === "EXHIBITOR" &&
              havePermission
            }
            message={handleBlockedNavigation}
          />
          {user?.role.type === "EXHIBITOR" && (
            <BoxContent>
              <Box>
                <TitleBox>
                  <Info filled size={17} />
                  <span>{intl.formatMessage({ id: "about_the_event" })}</span>
                  <span>{contractInfo?.eventEditionName}</span>
                </TitleBox>
                <Label>
                  <Calendar filled />
                  <span>
                    {convertToDateOnly(contractInfo?.eventStartDate)} a{" "}
                    {convertToDateOnly(contractInfo?.eventEndDate)}
                  </span>
                </Label>
                <Label>
                  <Pin filled />
                  <span>{contractInfo?.eventVenue}</span>
                </Label>
              </Box>
              <Box>
                <TitleBox>
                  <Image size={16} filled />
                  <span> {intl.formatMessage({ id: "event_materials" })}</span>
                </TitleBox>
                <Label>
                  <File filled />
                  <span>{intl.formatMessage({ id: "logo" })}</span>
                  {contractInfo.logo && (
                    <DocumentOpenImage
                      src={getLogoSrcFromHtml(contractInfo.logo)}
                    />
                  )}
                </Label>
              </Box>
            </BoxContent>
          )}
          <InfoContract>
            <TitleBox>
              <Info filled size={17} />
              <span>
                {intl.formatMessage({ id: "breadcrumb.contract_information" })}
              </span>
            </TitleBox>
            <BoxContent>
              {user?.role.type === "EXHIBITOR" ? (
                <>
                  <GroupLabel>
                    <Label>
                      <UserCircle size={17} filled />
                      <span>
                        {intl.formatMessage({
                          id: "responsible",
                        })}
                        {responsible}
                      </span>
                    </Label>
                    <Label>
                      <PaperPlane filled />
                      <span>
                        {intl.formatMessage({
                          id: "contact",
                        })}
                        : {contractInfo?.opportunity?.billingContact?.email}
                      </span>
                    </Label>
                  </GroupLabel>

                  <SpecialConditions>
                    <Label>
                      <Star filled />
                      <span>
                        {intl.formatMessage({
                          id: "special_conditions",
                        })}
                      </span>
                    </Label>
                    <HTMLCanvas
                      dangerouslySetInnerHTML={{
                        __html: contractInfo?.opportunity?.specialConditions,
                      }}
                    />
                  </SpecialConditions>
                </>
              ) : (
                <GroupLabel>
                  <Label>
                    <UserCircle size={17} filled />
                    <span>
                      {intl.formatMessage({
                        id: "name",
                      })}
                      : {contractInfo?.opportunity?.account?.accountName}
                    </span>
                  </Label>
                  <Label>
                    <UserFocus />
                    <span>
                      {intl.formatMessage({
                        id: "fantasy_name",
                      })}
                      {contractInfo?.opportunity?.account?.accountAlias}
                    </span>
                  </Label>
                  <Label>
                    <AddressBook />
                    <span>
                      {intl.formatMessage({
                        id: "document_cnpj",
                      })}
                      {
                        contractInfo?.opportunity?.account
                          ?.accountGovCompanyCode
                      }
                    </span>
                  </Label>
                  <Label>
                    <AddressBook />
                    <span>
                      {intl.formatMessage({
                        id: "types_of_service",
                      })}
                      :{" "}
                      {filterProvisionTypes.length &&
                        filterProvisionTypes.map((item, index) => {
                          if (index + 1 < filterProvisionTypes.length) {
                            return item.label + "; ";
                          }
                          return item.label;
                        })}
                    </span>
                  </Label>
                </GroupLabel>
              )}
            </BoxContent>
          </InfoContract>
          <Booth>
            <TitleBox>
              <Info filled size={17} />
              <span>
                {intl.formatMessage({
                  id: "booth",
                })}
              </span>
            </TitleBox>
            <FormField>
              <span>
                {intl.formatMessage({
                  id: "select_your_booth",
                })}
              </span>
              <Select
                options={boothsOptions}
                value={boothSelected}
                placeholder={intl.formatMessage({
                  id: "select",
                })}
                onChange={(value) => setBoothSelected(value)}
                style={{ width: "215px" }}
              />
            </FormField>
            <Label $isBooth>
              <HashStraight filled />
              <span>
                {intl.formatMessage({
                  id: "booth_number",
                })}
                &nbsp;
                {currentBoothSelected?.boothNumber}
              </span>
            </Label>
            <Label $isBooth>
              <Ticket filled />
              <span>
                {intl.formatMessage({
                  id: "area",
                })}
                &nbsp;
                {currentBoothSelected?.area || 0} m²
              </span>
            </Label>
            <Label $isBooth>
              <Pin filled />
              <span>
                {intl.formatMessage({
                  id: "booth_type",
                })}
                &nbsp;
                {currentBoothSelected?.type}
              </span>
            </Label>
            <Label $isBooth>
              <Image size={16} filled />
              <span>
                {intl.formatMessage({
                  id: "front_sign",
                })}
                <Tooltip
                  title={intl.formatMessage({
                    id: "tooltip.front_of_the_stand",
                  })}
                >
                  <span style={{ marginLeft: "5px" }}>
                    <Question size={16} color="#7FD0EB" filled />
                  </span>
                </Tooltip>
              </span>
            </Label>
            <FormField>
              <span>
                {intl.formatMessage(
                  { id: "name_on_the_front_of_the_stand" },
                  { length: 50 - frontSign.length }
                )}
              </span>
              <Input.Group compact>
                <Input
                  style={{ width: "490px" }}
                  onChange={({ target }) => setFrontSign(target.value)}
                  maxLength={50}
                  value={frontSign}
                  disabled={!havePermission}
                />
                <Btn
                  onClick={() =>
                    frontSign.length > 1 &&
                    havePermission &&
                    handleSaveHeadBand()
                  }
                  $disabled={frontSign.length < 1 || !havePermission}
                >
                  <Check color="#fff" size={20} />
                </Btn>
              </Input.Group>
            </FormField>
          </Booth>
          {user?.role.type === "EXHIBITOR" && (
            <>
              <ContractProductsServices
                data={contractInfo?.opportunity?.products}
              />
              <EcommerceProductsServices data={order} />
            </>
          )}
        </Content>
      )}
    </Container>
  );
};

export default Contract;
